import Hero from './Hero';
import './App.css';
import Navbar from './Navbar';
import './Hero.css'
import Services from './Services';
import  './Services.css'
import AdditionalServices from './AdditionalServices';
import Testimonials from './Testimonials';
import './Testimonial.css';
import Footer from './Footer';
import './Footer.css';

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <Services />
      <AdditionalServices />
      <Testimonials />
      <Footer />
    </>
  );
}

export default App;

import ServiceImage from '../src/assets/hero-img-two.jpg';

function AdditionalServices()
{
  const additionalServicesData = [
    'Automatically enroll certain workers into a pension scheme',
    'Make contributions on their workers behalf',
    'Register with The Pension Regulator',
    'Provide workers with certain information about the changes and how they will affect them',
    'Managing Opt Outs'
  ]



  return (
    <div className="additional-service-container-outer">
      
      
        <div className="additional-service-container-inner">
          <img src={ServiceImage} 
          alt="hero-img"
          className="additional-service-img"
          />

          <div>
          <h2>Additional Services</h2>
          <p>The date you need to start Auto Enrolment can be found on www.tpr.gov.uk/staging</p>

          <p>Employers can choose to bring forward staging date provided the Regulator is informed but cannot choose a later date</p>

          <p>We suggest that you choose your own Pension Provider. They have to be authorized by the government. Examples are NEST, NOW, Friendly Pensions, Scottish Life, Scottish widows etc</p>

          <p>A brief overview of what employers have to do</p>

          <div>
            <ul>
              {additionalServicesData.map(point => (
                <li className="point-styling" key={point}>{point}</li>
              ))}
            </ul>
          </div>
          
          </div>
        </div>
      </div>
  )
}

export default AdditionalServices
import {HiOutlineArrowNarrowDown, HiOutlineMenuAlt4, HiOutlineMinus} from 'react-icons/hi'
import React from 'react';
import PayrollLogo from '../src/assets/payroll_logo.png';

function Navbar() {

  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div className="navbar">
      <div className="container">

        <ul className={click ? "nav-menu active" : "nav-menu"}>

          <li><a href='#'>Home</a></li>
          <li><a href='#'>Our Services</a></li>
          <li><a href='#'>About Us</a></li>
          <li><a href='#'>News</a></li>
          <li><a href='#'>Contact Us</a></li>

        </ul>
        <div className="hamburger" onClick={handleClick}>
          {click ? <HiOutlineMinus/> : <HiOutlineMenuAlt4 /> }
        </div>
      </div>
    </div>
  )
}

export default Navbar
import { FaCalendarTimes, FaCashRegister, FaCcAmex, FaCreditCard, FaFacebookSquare, FaFax, FaInstagramSquare, FaMailBulk, FaMapMarkedAlt, FaPhoneAlt, FaTwitterSquare, FaWhatsappSquare, FaYoutubeSquare } from 'react-icons/fa';
import Logo from '../src/assets/payroll_logo.png';


function Footer() {
  return (
    <footer>
      <h2 className="footer-heading"><b>The Payroll lab | <img src={Logo} width={70} height={80} alt="logo" /></b></h2>
      <section className="main-footer">
        
        
        <div className="main-item">
          <ul>

            <li><span><FaMapMarkedAlt className="ft-icon" /></span>[Postcode | address here]</li>
            <li><a href="tel:02045423390"><span><FaPhoneAlt className="ft-icon" /></span>0204 542 3390</a></li>
            <li><span><FaMailBulk className="ft-icon" /></span>info@payroll-lab.com</li>
          
          </ul>

          <ul className="ft-links">

            <li>Contact Us</li>
            <li>About</li>
            <li>Testimonials</li>
            <li>Our Services</li>
            <li>Login</li>

          </ul>
        </div>

      </section >




     {/* Footer Legal section  */}
      <section className="legal-section">
        <ul className="legal-list">
          <button className="ft-icon-social facebook">
            <FaFacebookSquare className="fa fa-facebook icon" />
          </button>

          <button className="ft-icon-social twitter">
            <FaTwitterSquare className="fa fa-facebook icon" />
          </button>

          <button className="ft-icon-social youtube">
            <FaYoutubeSquare className="fa fa-facebook icon" />
          </button>

          <button className="ft-icon-social whatsapp">
            <FaWhatsappSquare className="fa fa-facebook icon" />
          </button>

          {/* <li><FaInstagramSquare className="ft-icon-social " /></li>
          <li><FaFacebookSquare className="ft-icon-social facebook" /></li>
          <li><FaTwitterSquare className="ft-icon-social" /></li>
          <li><FaYoutubeSquare className="ft-icon-social" /></li> */}

          <li className="copyright">&copy; 2022 Copyright | The Payroll Lab</li>
        </ul>
      </section>


    </footer >

  )
}

export default Footer;
import Typed from 'react-typed';

function Hero() {
  const heroServices = ['100% of our energy', '100% of our expertise', '100% of our enthusiasm'];



  return (
    <div className="hero">
      <div className="content">
        <h2>The <span className="hero-title">Payroll</span> Lab</h2>
          <p>The General Data Protection Regulation (GDPR) – are you ready?

          All employers with eligible employees must now have them Automatically Enrolled in a workplace pension. If not, you may face fines!

          Need help? Contact us.</p>

          <p>Providing UK employers with payroll services and business support. The Payroll Lab will not only provide your business with payroll solutions, we will ensure you of:</p>
        <div className="self-typed-container">
          <Typed 
            typeSpeed={80}
            backSpeed={80}
            strings={heroServices}
            backDelay={1000}
            loop
            showCursor
            cursorChar="|"
            className="self-typed"
            />
        </div>
        

        
        <div>
          <button className="contact-us-btn">Contact us today</button>
          <button className="about-us-btn">About us</button>
        </div>

      </div>
    </div>
  )
}

export default Hero
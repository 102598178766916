import ImageOne from "../src/assets/payroll_logo.png"

import { FaCashRegister, FaBusinessTime, FaClock,FaClipboardList } from 'react-icons/fa';

function Services() {
  const servicesData = [
    {
      "id": 1,
      "heading": "lorem sample sample sample lorem sample sample sampllorem sample sample sampllorem sample sample sampl",
      "image": <FaCashRegister /> ,
      "alt": "Image one"
    },
    {
      "id": 2,
      "heading": "lorem sample sample 2 sample lorem sample sample sampl lorem sample sample sampl",
      "image": <FaBusinessTime />,
      "alt": "Image 2"


    },
    {
      "id": 3,
      "heading": "lorem sample sample 3 sample lorem sample sample sampl lorem sample sample sampl",
      "image": <FaClock />,
      "alt": "Image 3"


    },
    {
      "id": 4,
      "heading": "lorem sample sample 4 sample",
      "image": <FaClipboardList />
    }

  ]



  return (
    <div className="services-main-container">

    <div className="services-container">
      <h2>Services we offer</h2> 
      <hr className="underline"/>
      <div>
        <p>We are here to make payroll management run smoothly for your business.</p>
        <p>
        We have many years experience in dealing with all aspects of payroll management and business support services. 
        </p>
        <p>We are dedicated to providing UK companies with tailored, value for money payroll services, as well as general business support services: accounting and bookkeeping, human resources (HR), pension scheme administration and regulatory compliance.</p>

        <p>
        From receiving your first timesheet to producing your Year End, The Payroll Lab will take care of your payroll outsourcing, be it paid weekly, fortnightly, four weekly or monthly; no need to stress about statutory payments, student loans, pensions, attachments and any other payments and deductions. Giving you the peace of mind to get on with running your business.
        </p>
        <p>
        The Payroll Lab believe employees are the most important asset of your business, paying them accurately and on time ensures a motivated and productive workplace.
        </p>
      </div>
      <br />
      <p className="service-help">How can we help you: </p>
      
      <div className="services-list">
            
            {servicesData.map((s) => (
              
                <div key={s.id}>
                  <span className="service-icons">{s.image}</span>
                  

                  <p>{s.heading}</p>

                </div>
              
              
            ))}




      </div>



    </div>
    </div>
  )
}

export default Services
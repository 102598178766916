import { Carousel } from 'react-responsive-carousel';
import TestimonialImageOne from '../src/assets/payroll_logo.png';


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function Testimonials() {
  return (
  <div className="testimonial-container">
      <div className="testimonial-main-container">

    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={5000}
    >
      <div>
        <img className="testimonial-img" src={TestimonialImageOne} alt="testimonial-one"/>

        <div className="myCarousel">
          <h3>EXAMPLE ONE</h3>
          <h4>OCCUPATION</h4>
          <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC</p>
        </div>
      
      </div>
      <div>
        <img className="testimonial-img" src={TestimonialImageOne} alt="testimonial-one"/>

        <div className="myCarousel">
          <h3>EXAMPLE two</h3>
          <h4>OCCUPATION</h4>
          <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC</p>
        </div>
      </div>
      <div>
        <img className="testimonial-img" src={TestimonialImageOne} alt="testimonial-one"/>

        <div className="myCarousel">
          <h3>EXAMPLE ONE</h3>
          <h4>OCCUPATION</h4>
          <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC</p>
        </div>
      </div>
    </Carousel>
    </div>
    </div>
  )
}

export default Testimonials